'use strict'

###*
 # @ngdoc object
 # @name mundoAdmin.incidents.controller:IncidentCtrl

 # @description

###
class IncidentCtrl
  ### @ngInject ###
  constructor: ->
    @ctrlName = 'IncidentCtrl'

angular
  .module('mundoAdmin.incidents')
  .controller 'IncidentCtrl', IncidentCtrl
